const { REACT_APP_BACKEND_API } = process.env;
export const URL = REACT_APP_BACKEND_API;
// export const URL = 'https://8b1k7a3ksb.execute-api.eu-north-1.amazonaws.com/Prod/';//testh2
// export const URL = 'https://u52edmc6re.execute-api.eu-north-1.amazonaws.com/Prod/';//test1
export const promoCodeUrl = 'https://5k8nyw57d0.execute-api.eu-central-1.amazonaws.com/Prod/';


export const countries = ['Россия', 'Украина', 'Армения', 'Беларусь', 'Казахстан', 'Киргизия']

export const initialGenderData = {
  name: '',
  gender: '',
  character: 0,
  page: 'INIT',
  wish: '',
  cover_type: 'hard',
  cover_colour: 'pink',
  orderId: null,
}

export const countriesISO = ['RU', 'UA', 'AM', 'BY', 'KZ', 'KG']

export const countriesСurrency = {
  ru: 'руб',
  uk: 'грн'
}

export const mailForCountry = {
  ru: ['Доставка курьером'],
  uk: ['Новая Почта - курьером до двери']
}

export const bookType = {
  hard: 'hard',
  soft: 'soft',
}

export const skuType = {
  sku1: 'SKU1',
  sku2: 'SKU2',
}

export const priceBook = {
  uk: {
    hard: 829,
    soft: 559,
  },
  ru: {
    hard: 2990,
    soft: 1990,
  }
}

export const regions = [
  {
    id: 0,
    country: 'Россия',
    title: 'Москва в пределах МКАД',
    courier: {
      price: 350,
      expectationFrom: 3,
      expectationTo: 5,
    },
    mail: {
      price: 194,
      expectationFrom: 4,
      expectationTo: 6,
    },
    courierPaymentPossibility: true,
  }, {
    id: 1,
    country: 'Россия',
    title: 'Москва за МКАД и МО',
    courier: {
      price: 350,
      expectationFrom: 4,
      expectationTo: 6,
    },
    mail: {
      price: 194,
      expectationFrom: 4,
      expectationTo: 6,
    },
    courierPaymentPossibility: true,
  }, {
    id: 2,
    country: 'Россия',
    title: 'Другие города России',
    courier: {
      price: 400,
      expectationFrom: 5,
      expectationTo: 10,
    },
    mail: {
      price: 263,
      expectationFrom: 6,
      expectationTo: 10,
    },
    courierPaymentPossibility: true,
  }, {
    id: 3,
    country: 'Россия',
    title: 'Калининградская область',
    courier: {
      price: 1300,
      expectationFrom: 9,
      expectationTo: 18,
    },
    mail: {
      price: 263,
      expectationFrom: 8,
      expectationTo: 10,
    },
    courierPaymentPossibility: false,
  }, {
    id: 4,
    country: 'Россия',
    title: 'Хабаровский край',
    courier: {
      price: 1300,
      expectationFrom: 9,
      expectationTo: 18,
    },
    mail: {
      price: 370,
      expectationFrom: 10,
      expectationTo: 12,
    },
    courierPaymentPossibility: false,
  }, {
    id: 5,
    country: 'Россия',
    title: 'Амурская область',
    courier: {
      price: 1300,
      expectationFrom: 9,
      expectationTo: 18,
    },
    mail: {
      price: 370,
      expectationFrom: 12,
      expectationTo: 14,
    },
    courierPaymentPossibility: false,
  }, {
    id: 6,
    country: 'Россия',
    title: 'Камчатский край',
    courier: {
      price: 1300,
      expectationFrom: 9,
      expectationTo: 18,
    },
    mail: {
      price: 370,
      expectationFrom: 25,
      expectationTo: 27,
    },
    courierPaymentPossibility: false,
  }, {
    id: 7,
    country: 'Россия',
    title: 'Магаданская область',
    courier: {
      price: 1300,
      expectationFrom: 9,
      expectationTo: 18,
    },
    mail: {
      price: 370,
      expectationFrom: 25,
      expectationTo: 27,
    },
    courierPaymentPossibility: false,
  }, {
    id: 8,
    country: 'Россия',
    title: 'Ненецкий АО',
    courier: {
      price: 1300,
      expectationFrom: 9,
      expectationTo: 18,
    },
    mail: {
      price: 526,
      expectationFrom: 12,
      expectationTo: 14,
    },
    courierPaymentPossibility: false,
  }, {
    id: 9,
    country: 'Россия',
    title: 'Приморский край',
    courier: {
      price: 1300,
      expectationFrom: 9,
      expectationTo: 18,
    },
    mail: {
      price: 370,
      expectationFrom: 11,
      expectationTo: 13,
    },
    courierPaymentPossibility: false,
  }, {
    id: 10,
    country: 'Россия',
    title: 'Республика Саха (Якутия)',
    courier: {
      price: 1300,
      expectationFrom: 9,
      expectationTo: 18,
    },
    mail: {
      price: 658,
      expectationFrom: 16,
      expectationTo: 18,
    },
    courierPaymentPossibility: false,
  }, {
    id: 11,
    country: 'Россия',
    title: 'Сахалинская область',
    courier: {
      price: 1300,
      expectationFrom: 9,
      expectationTo: 18,
    },
    mail: {
      price: 370,
      expectationFrom: 17,
      expectationTo: 19,
    },
    courierPaymentPossibility: false,
  }, {
    id: 12,
    country: 'Россия',
    title: 'Усть-Кутск',
    courier: {
      price: 1300,
      expectationFrom: 9,
      expectationTo: 18,
    },
    mail: {
      price: 329,
      expectationFrom: 11,
      expectationTo: 13,
    },
    courierPaymentPossibility: false,
  }, {
    id: 13,
    country: 'Россия',
    title: 'Чукотский АО',
    courier: {
      price: 1300,
      expectationFrom: 9,
      expectationTo: 18,
    },
    mail: {
      price: 740,
      expectationFrom: 9,
      expectationTo: 11,
    },
    courierPaymentPossibility: false,
  }, {
    id: 14,
    country: 'Россия',
    title: 'Ямало-Ненецкий АО',
    courier: {
      price: 1300,
      expectationFrom: 9,
      expectationTo: 18,
    },
    mail: {
      price: 274,
      expectationFrom: 7,
      expectationTo: 10,
    },
    courierPaymentPossibility: false,
  }, {
    id: 15,
    country: 'Армения',
    title: 'Ереван',
    courier: {
      price: 450,
      expectationFrom: 5,
      expectationTo: 7,
    },
    mail: {
      price: 648,
      expectationFrom: 4,
      expectationTo: 5,
    },
    courierPaymentPossibility: false,
  }, {
    id: 16,
    country: 'Беларусь',
    title: 'Минск',
    courier: {
      price: 450,
      expectationFrom: 5,
      expectationTo: 7,
    },
    courierPaymentPossibility: false,
  }, {
    id: 17,
    country: 'Беларусь',
    title: 'Могилёв',
    courier: {
      price: 450,
      expectationFrom: 5,
      expectationTo: 7,
    },
    courierPaymentPossibility: false,
  }, {
    id: 18,
    country: 'Беларусь',
    title: 'Гродно',
    courier: {
      price: 450,
      expectationFrom: 5,
      expectationTo: 7,
    },
    courierPaymentPossibility: false,
  }, {
    id: 19,
    country: 'Беларусь',
    title: 'Гомель',
    courier: {
      price: 450,
      expectationFrom: 5,
      expectationTo: 7,
    },
    courierPaymentPossibility: false,
  }, {
    id: 20,
    country: 'Беларусь',
    title: 'Витебск',
    courier: {
      price: 450,
      expectationFrom: 5,
      expectationTo: 7,
    },
    courierPaymentPossibility: false,
  }, {
    id: 21,
    country: 'Беларусь',
    title: 'Брест',
    courier: {
      price: 450,
      expectationFrom: 5,
      expectationTo: 7,
    },
    courierPaymentPossibility: false,
  }, {
    id: 22,
    country: 'Казахстан',
    title: 'Алматы',
    courier: {
      price: 700,
      expectationFrom: 5,
      expectationTo: 7,
    },
    mail: {
      price: 648,
      expectationFrom: 7,
      expectationTo: 10,
    },
    courierPaymentPossibility: false,
  }, {
    id: 23,
    country: 'Казахстан',
    title: 'Нур-Султан',
    courier: {
      price: 700,
      expectationFrom: 5,
      expectationTo: 7,
    },
    mail: {
      price: 648,
      expectationFrom: 7,
      expectationTo: 10,
    },
    courierPaymentPossibility: false,
  }, {
    id: 24,
    country: 'Киргизия',
    title: 'Бишкек',
    courier: {
      price: 800,
      expectationFrom: 5,
      expectationTo: 7,
    },
    mail: {
      price: 648,
      expectationFrom: 4,
      expectationTo: 5,
    },
    courierPaymentPossibility: false,
  }, {
    id: 25,
    country: 'Киргизия',
    title: 'Ош',
    courier: {
      price: 800,
      expectationFrom: 5,
      expectationTo: 7,
    },
    mail: {
      price: 648,
      expectationFrom: 4,
      expectationTo: 5,
    },
    courierPaymentPossibility: false,
  },


  {
    id: 26,
    country: 'Украина',
    title: 'Винница',
    courier: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
    mail: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
courierPaymentPossibility: true,
  },
  {
    id: 27,
    country: 'Украина',
    title: 'Днепр',
    courier: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
    mail: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
courierPaymentPossibility: true,
  },
  {
    id: 28,
    country: 'Украина',
    title: 'Житомир',
    courier: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
    mail: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
courierPaymentPossibility: true,
  },
  {
    id: 29,
    country: 'Украина',
    title: 'Запорожье',
    courier: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
    mail: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
courierPaymentPossibility: true,
  },
  {
    id: 30,
    country: 'Украина',
    title: 'Ивано-Франковск',
    courier: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
    mail: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
courierPaymentPossibility: true,
  },
  {
    id: 31,
    country: 'Украина',
    title: 'Киев',
    courier: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
    mail: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
courierPaymentPossibility: true,
  },
  {
    id: 32,
    country: 'Украина',
    title: 'Кривой Рог',
    courier: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
    mail: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
courierPaymentPossibility: true,
  },
  {
    id: 33,
    country: 'Украина',
    title: 'Луцк',
    courier: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
    mail: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
courierPaymentPossibility: true,
  },
  {
    id: 34,
    country: 'Украина',
    title: 'Львов',
    courier: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
    mail: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
courierPaymentPossibility: true,
  },
  {
    id: 35,
    country: 'Украина',
    title: 'Николаев',
    courier: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
    mail: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
courierPaymentPossibility: true,
  },
  {
    id: 36,
    country: 'Украина',
    title: 'Одесса',
    courier: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
    mail: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
courierPaymentPossibility: true,
  },
  {
    id: 37,
    country: 'Украина',
    title: 'Полтава',
    courier: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
    mail: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
courierPaymentPossibility: true,
  },
  {
    id: 38,
    country: 'Украина',
    title: 'Ровно',
    courier: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
    mail: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
courierPaymentPossibility: true,
  },
  {
    id: 39,
    country: 'Украина',
    title: 'Сумы',
    courier: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
    mail: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
courierPaymentPossibility: true,
  },
  {
    id: 40,
    country: 'Украина',
    title: 'Тернополь',
    courier: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
    mail: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
courierPaymentPossibility: true,
  },
  {
    id: 41,
    country: 'Украина',
    title: 'Харьков',
    courier: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
    mail: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
courierPaymentPossibility: true,
  },
  {
    id: 42,
    country: 'Украина',
    title: 'Хмельницкий',
    courier: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
    mail: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
courierPaymentPossibility: true,
  },
  {
    id: 43,
    country: 'Украина',
    title: 'Черкассы',
    courier: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
    mail: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
courierPaymentPossibility: true,
  },
  {
    id: 44,
    country: 'Украина',
    title: 'Чернигов',
    courier: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
    mail: {
      price: 70,
      expectationFrom: 5,
      expectationTo: 5,
    },
courierPaymentPossibility: true,
  },
  {
    id: 45,
    country: 'Украина',
    title: 'Черновцы',
    courier: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
    mail: {
      price: 70,
      expectationFrom: 6,
      expectationTo: 6,
    },
courierPaymentPossibility: true,
  },
]

