import axios from 'axios'
import { URL } from './constants';

export const apiConfig = {
  baseURL: URL,
}

const axiosInstance = axios.create(apiConfig)

export default axiosInstance
